export const displaySideNav = () => {
  const burger = document.querySelector(".nav-burger");
  const closeNav = document.querySelector(".nav-close");

  if (burger) {
    const navContainer = document.querySelector(".side-nav-container");
    burger.addEventListener("click", () => {
      navContainer.classList.add("visible")
    })
    closeNav.addEventListener("click", () => {
      navContainer.classList.remove("visible")
    })
  }
}